<template>
  <div style="padding: 20px">
    <div class="handle-box">
      <el-input v-model="query.equipmentName" clearable style="width: 200px;margin-right: 10px" placeholder="器材名称"></el-input>
      <el-input v-model="query.coachName" clearable style="width: 200px;margin-right: 10px" placeholder="教练姓名"></el-input>
      <el-select v-model="query.regionId" clearable placeholder="城市" style="margin-right: 10px;width: 140px">
        <el-option v-for="(item, index) in regionList" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-date-picker
          style="width: 260px"
          class="handle-input"
          v-model="query.temporaryTime" clearable
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
      </el-date-picker>
      <el-button type="primary" style="margin-left: 20px"  @click="select">搜索</el-button>
      <el-button type="primary" style="margin-left: 20px"  @click="getEquipmentAddMovementsListExcel">导出</el-button>
    </div>
    <el-table
        :data="tableData"
        v-loading="tableLoading"
        height="610px"
        style="width: 100%;margin: 20px 0;"
        :row-style="{height:20+'px'}"
        :cell-style="{padding:16+'px'}"
        row-key="id"
        border>
      <el-table-column prop="id" label="id" width="140"/>

      <el-table-column
          prop="equipmentName"
          label="器材名称">
      </el-table-column>
      <el-table-column
          prop="coachName"
          label="教练 - Id" width="180">
        <template #default="scope">
          {{ scope.row.coachName }} - {{scope.row.coachId }}
        </template>
      </el-table-column>
      <el-table-column
          prop="coachName"
          label="入库数量"
          width="140">
        <template #default="scope">
          {{ scope.row.num }} {{scope.row.equUnit }}
        </template>
      </el-table-column>
      <el-table-column
          prop="regionName"
          label="所属仓库"
          width="140">
      </el-table-column>
      <el-table-column
          prop="beiZhu"
          label="备注"
          width="240">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="创建时间"
          width="240">
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { equipmentMovements,getEquipmentAddMovementsListExcel} from "@/api/equipment";
import { selectAreaByExpress } from "@/api/area";
export default {
  name: "EquipmentMovements",
  data() {
    return {
      query:{
        coachName: "",
        regionId: null,
        equipmentName: "",
        pageIndex: 1,
        pageSize: 10,
        payEndTime: null,
        payStartTime: null,
        temporaryTime:null,
      },
      pageTotal:0,
      regionList:[],
      tableData:[],
      tableLoading:false
    }
  },
  methods:{
    select(){
      this.query.payStartTime = this.query.temporaryTime == null ? null : this.dateFormat(this.query.temporaryTime[0]);
      this.query.payEndTime = this.query.temporaryTime == null ? null: this.dateFormat(this.query.temporaryTime[1]);
      equipmentMovements(this.query).then(val=>{
        if (val.code == 200) {
          this.tableData = val.data.records;
          this.pageTotal = val.data.total;
          console.log(this.tableData)
        } else {
          this.$message.error("获取表格数据失败");
        }
      })
    },
    handlePageChange(val){
      this.query.pageIndex = val;
      this.select();
    },
    getEquipmentAddMovementsListExcel(){
      getEquipmentAddMovementsListExcel(this.query);
      this.$message.success("下载成功 请在右上角查看！");

    },
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
  },
  created() {
    this.select();
    selectAreaByExpress().then(res => {
      if (res.code == 200) {
        this.regionList = res.data
      } else {
        this.$message.error("获取城市数据失败");
      }
    })

  },
}
</script>

<style scoped>

</style>